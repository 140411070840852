
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

import calendar from '../../resources/icons/calendar.svg';
import user from '../../resources/icons/user.svg';

export default defineComponent({
  name: 'TabsLayout',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet
  },
  setup() {
    return {
      calendar,
      user
    };
  }
});
